<template>
	<base-col col="12" lg="4">
		<div class="product-content">
			<base-font class="title" variant="paragraph" tag="h2" weight="bold" uppercase>
				{{value.heading}}
			</base-font>
			<ul class="product-list ">
				<li class="product-list-item" v-for="({item}, i) in value.list" :key="item">
					<base-font class="product-list-counter" size="md" variant="price">{{i + 1}}</base-font>
					<div v-html="item"></div>
				</li>
			</ul>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.product-content {
	& :deep(.title) {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		&::after {
			content: '';
			flex-grow: 1;
			margin-left: 2rem;
			height: 1px;
			background-color: $gray-100;
		}
	}
	.product-list {
		list-style: none;
		margin: 0;
		padding: 0;
		&-item {
			display: flex;
			align-items: center;
			margin-bottom: 3rem;
		}
		& :deep(.product-list-counter) {
			margin: 0 2rem 0 0;
		}
	}
}
</style>
